import ConvertExperiments from './convert_experiments';

export function preventRunOnTubolinkCache(callback) {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
        // Don't execute the code on Turbolinks cached pages
        callback();
    }
}

window.preventRunOnTubolinkCache = preventRunOnTubolinkCache;
window.ConvertExperiments = ConvertExperiments;

(function() {
    // Event listener
    document.addEventListener('click', function(event) {
        if (event.target.closest('[data-convert-goal]')) {
            if (!window.Sofatutor || !window.Sofatutor.consentGivenForConvert) {
                return;
            }

            var el = event.target.closest('[data-convert-goal]');
            var goalId = el.getAttribute('data-convert-goal').toString();

            var trackingQueueString = getCookie('_sofatutor_convert_queue') || '[]';
            var trackingQueue = JSON.parse(trackingQueueString);
            trackingQueue.push([goalId]);

            setCookie('_sofatutor_convert_queue', JSON.stringify(trackingQueue), { path: '/' });
        }
    });

    // Local function to get cookie value
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    // Local function to set cookie value
    function setCookie(name, value, options) {
        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }
})();

