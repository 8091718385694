export default class ConvertTracking {
    constructor(trackedExperiments = {}, goals = []) {
        window.Sofatutor = window.Sofatutor || { trackingEnabled: true, logTracking: console.log };
        this.goals = goals;
        this.trackedExperiments = trackedExperiments;
        this.trackingEnabled = Sofatutor.trackingEnabled;
        this.trackedGoals = [];
        this._trackExperiments();
        this._eventify(this.goals, this._onGoalPush.bind(this));
        this._handleCookie('_sofatutor_convert_queue');
    }

    trigger(goalId) {
        this.goals.push(goalId);
    }

    _handleCookie(cookieName) {
        const cookieValue = this._getCookie(cookieName);
        if (cookieValue) {
            JSON.parse(cookieValue).forEach(goalId => this.trigger(goalId));
            this._removeCookie(cookieName);
        }
    }

    _trackExperiments() {
        const data = { goals: this.goals };
        if (this.trackingEnabled) {
            this.sendToTrackingAPI('/experiments/track', data, () => {
                this.trackedGoals.push(...this.goals);
                this.goals = []; // Reset goals after successful tracking
                Sofatutor.logTracking(['Experiments and Goals tracked:', data]);
            });
        } else {
            Sofatutor.logTracking(['Experiments and Goals tracked:', data]);
            this.trackedGoals.push(...this.goals);
            this.goals = [];
        }
    }

    _onGoalPush(goalId) {
        if (this.trackedGoals.includes(goalId)) return;

        if (this.trackingEnabled) {
            this.sendToTrackingAPI('/experiments/track', { goals: [goalId] }, () => {
                Sofatutor.logTracking(['Goal tracked:', goalId]);
                this.trackedGoals.push(goalId);
            });
        } else {
            Sofatutor.logTracking(['Goal tracked:', goalId]);
            this.trackedGoals.push(goalId);
        }
    }

    sendToTrackingAPI(url, data, callback) {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => callback(response))
        .catch(error => console.error('Error tracking API:', error));
    }

    _eventify(arr, callback) {
        arr.push = (e) => {
            Array.prototype.push.call(arr, e);
            callback(e);
        };
    }

    _getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    _removeCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}
